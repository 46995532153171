import React, { useState, useEffect } from 'react';
import {UserInfo} from '../../components';
import {CustomInput, NumberInputWithSlider} from '../../components/inputs/CustomInput';
import {TokenBalance} from '../../modules/tokenBalance';
import {Approve} from '../../modules/actions/approve';
import {VDeposit} from '../../modules/actions/vDeposit';
import {VClaim} from '../../modules/actions/vClaim';
import {VDepositInfo, VTimes} from '../../modules/vesting';
import {Allowance} from '../../modules/allowance'
import blueBlack from '../../assets/images/background/blue-black.jpg';
import moment from 'moment';
import { useGlobalState } from '../../components/context/GlobalStateProvider';
import './vesting.scss'

const Vesting = () => {
  const { storedTokenInfo, setStoredTokenInfo, chainData, setChainData, walletInfo, setWalletInfo, isConnected } = useGlobalState();
  const [input, setInput] = useState(0)
  const [inputErr, setInputErr] = useState()
  const [vResponse, setVResponseData] = useState()
  const [vBalances, setVBalances] = useState()
  const [buttonManager, setButtonManager] = useState({ approve: false, deposit: false, withdraw: false, msg: null })
  const [allowanceData, setAllowanceData] = useState()
  const [isApproved, setIsApproved] = useState(false)

  function checkApproved(allowanceVal) {
    if (allowanceData['allowance'] > input) {
      console.log('setIsApproved(true):', allowanceData['allowance'], input)
      setIsApproved(true)
      console.log('isApproved:?', isApproved)
    }
  }
  //checkApproved(vResponse['allowanceInfo'])

  useEffect(() => {
    if (isConnected) {
      const tmpFunc = async () => {
        try {
          console.log("stored:", chainData, walletInfo)
          let allowance = await Allowance(chainData.tokenAddress, chainData.vestingAddress, walletInfo.provider, walletInfo.signer)
          console.log("stored:", allowance)
        } catch (e) { console.log("vesting", e) }
      }
      tmpFunc()

      if (!walletInfo.signer) {
        setButtonManager({ approve: false, deposit: false, withdraw: false, msg: 'Please Connect Wallet!' })
      } else {
        if (!isApproved) {
          setButtonManager({ approve: true, deposit: true, withdraw: true, msg: 'Please Approve Wallet!' })
        } else { setButtonManager({ approve: true, deposit: true, withdraw: true, msg: 'Wallet Already Approved!' }) }

        //if (input > max) set buttonManager.deposit false
      }
    } else { console.log("nada") }
  }, [isConnected]);

  useEffect(() => {
    if (!isConnected) {
      if (!chainData) {
        console.log("collectedData noDat", chainData, isConnected)
        return
      }
    }
    const getData = async () => {
      console.log("collectedData")
      try {

        let ubalance = await TokenBalance(chainData.tokenAddress, walletInfo.signer.address, walletInfo.provider, storedTokenInfo)
        let vbalance = await TokenBalance(chainData.tokenAddress, chainData.vestingAddress, walletInfo.provider, storedTokenInfo)
        setVBalances({ 'vBalance': vbalance, 'uBalance': ubalance })
        //setInput(ubalance)
        let vestingTimeData = await VTimes(chainData.vestingAddress, walletInfo.provider, walletInfo.signer)
        let userDepositInfo = await VDepositInfo(chainData.vestingAddress, chainData.tokenAddress, walletInfo.provider, walletInfo.signer)
        let allowanceInfo = await Allowance(chainData.tokenAddress, chainData.vestingAddress, walletInfo.provider, walletInfo.signer.address)
        //let fBalance = await FInfo( chainData.tokenAddress, walletInfo.provider, walletInfo.signer.address )
        console.log('allowanceInfo:', allowanceInfo)
        setAllowanceData({ 'allowance': allowanceInfo })
        //checkApproved( allowanceInfo )
        //console.log("collectedData1:", vestingTimeData, userDepositInfo, allowanceInfo, vBalance, uBalance )


        setVResponseData({
          'vestingTimeData': vestingTimeData,
          'userDepositInfo': userDepositInfo,
          'allowanceInfo': allowanceInfo
        })

        //console.log('set balances:', vBalance, uBalance, vestingTimeData )

      } catch (error) {
        console.log('collectedData2:', error)
      }
      console.log("collectedData2")
    };

    // Set the refresh interval to, for example, 5000 milliseconds (5 seconds)
    const refreshInterval = setInterval(getData, 1500);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(refreshInterval);
  })

  function balanceData() {
    const balDict = {}
    try {
      balDict['userBal'] = rnd(vBalances['uBalance'])
      balDict['vestingBal'] = rnd(vBalances['vBalance'])
    } catch {
      balDict['userBal'] = 0
      balDict['vestingBal'] = 0
    }
    return balDict
  }

  var bln = balanceData()

  // get Vesting Times data
  function getVTimes() {
    const vTimesDict = {}
    var t = new Date();
    try {
      var strt = parseInt(Number(vResponse['vestingTimeData'][0][0]))
      vTimesDict['start'] = moment.unix(strt).format("YYYY-MM-DD");

      var end = parseInt(Number(vResponse['vestingTimeData'][0][1]))
      vTimesDict['end'] = moment.unix(end).format("YYYY-MM-DD"); //

      var lck = Number(vResponse['vestingTimeData'][0][2])
      vTimesDict['lock'] = lck / (24 * 3600);//Number(vResponse['vestingTimeData'][0][2])

      vTimesDict['duration'] = rnd((end - strt) / 86400)
    }
    catch (e) {
      console.log('moment:', e);
      vTimesDict['start'] = 0
      vTimesDict['end'] = 0
      vTimesDict['lock'] = 0
      vTimesDict['duration'] = 0

    }
    return vTimesDict
  }

  var vTimes = getVTimes()


  // get Vesting user data
  function getVUserData(lockhrs) {
    const vUdict = {}
    try {
      var claimAmount = vResponse['userDepositInfo'][1]
      vUdict['clAmount'] = rnd(parseInt(Number(claimAmount)) / (10 ** 9))

      var lastclaimTime = parseInt(vResponse['userDepositInfo'][2])
      vUdict['lClaimTime'] = moment.unix(lastclaimTime).format("YYYY-MM-DD HH:mm");

      var nextClaimTime = parseInt(Number(vResponse['userDepositInfo'][2])) + (lockhrs * 3600 * 24)
      if (vResponse['userDepositInfo'][2] == 0) {
        nextClaimTime = Number(vResponse['vestingTimeData'][0][0]) + (lockhrs * 3600 * 24)
        vUdict['lClaimTime'] = 'NA'
      }
      vUdict['nClaimTime'] = moment.unix(nextClaimTime).format("YYYY-MM-DD HH:mm");



      var nextClaim = vResponse['userDepositInfo'][3]
      vUdict['nxclAmount'] = rnd(parseInt(Number(nextClaim)) / (10 ** 9))

      var cycles = vResponse['userDepositInfo'][4]
      vUdict['cycle'] = parseInt(Number(cycles))

      vUdict['waitTime'] = (nextClaimTime - moment.unix(Date.now()))

      var depositAmount = rnd(parseInt(Number(vResponse['userDepositInfo'][0])) / (10 ** 9))
      vUdict['dpAmount'] = depositAmount
    }
    catch (e) {
      console.log('vdict', e)
      vUdict['clAmount'] = 0
      vUdict['lClaimTime'] = 0
      vUdict['dpAmount'] = 0
      vUdict['waitTime'] = 0
      vUdict['nClaimTime'] = 0
      vUdict['nxclAmount'] = 0
    }
    return vUdict
  }
  var vUData = getVUserData(vTimes['lock'], vTimes['start'])
  var errorString = 'You Must Connect Wallet Before Using This Feature!'

  const approve = (e) => {
    e.preventDefault();
    console.log(walletInfo, 'walletInfo')
    if (!walletInfo) {
      setInputErr(errorString)
    } else {
      try {
        var inp = input * 10 ** 9
        console.log('input balance;', inp)
        var aproval = Approve(chainData.tokenAddress, chainData.vestingAddress, inp, walletInfo.provider, walletInfo.signer)
      } catch (e) {
        console.log("aprove function failed:", e)
      }
    }
  }

  const deposit = (e) => {
    e.preventDefault();
    if (!walletInfo) {
      setInputErr(errorString)
      return
    }

    try { //vestingAddress, tokenAddress, amount, signer , provider
      var inp = input * 10 ** 9
      var deposits = VDeposit(chainData.vestingAddress, chainData.tokenAddress, inp, walletInfo.signer, walletInfo.provider)
      console.log('var deposits:', deposits)
    } catch (e) {
      console.log("deposit function failed:", e)
    }
    //var deposit = Deposit()

  }//==

  function rnd(num) {
    var rnum = Math.round(num * 100) / 100
    return rnum
  }

  const withdraw = (e) => {
    e.preventDefault();
    if (!walletInfo) {
      setInputErr(errorString)
      return
    }
    //get value from withdraw field
    try { //vestingAddress, tokenAddress, provider, signer
      var claim = VClaim(chainData.vestingAddress, chainData.tokenAddress, walletInfo.provider, walletInfo.signer)


      console.log('claim log:', claim)


    } catch (e) { console.log("claim function failed:", e) }

  }
  /* <UserInfo chainData={chainData} walletInfo={walletInfo} /> */
  return (
    <>
      <section className="newsletter section">
        <div className='demoDapp-container'>
          {/*<UserInfo chainData={chainData} walletInfo={walletInfo} />*/}

          <div className='centered-div customForm nftPage'><p>

            <h3 className="vhead"> Vesting Your Tokens on Arbitrum</h3>
            During migration we keep your FRIENDSHIP safe by storing it in a smart contract.
            You will receive an Airdrop of tokens 1:1 as we launch on Pulsechain.
            Every 4 days you will be able to claim back your tokens into your wallet.
            This is gradually release is to incentivise holding & price stability on both chains. </p>
            <p>
              <b>$FRIENDSHIP </b> <br />
              <div className="arbi">Total Locked in Contract: {bln['vestingBal']}<br />
                In My Wallet: {bln['userBal']} </div><br />
              Duration: {vTimes['duration']} Days || Full Unlock:  {vTimes['end']} <br />

              Withdraw Every: {vTimes['lock']} Days<br />
              <b>My Total Vested: {vUData['dpAmount']}</b> </p>
            <CustomInput input={input} setInput={setInput} inputErr={inputErr} setInputErr={setInputErr} maxVal={bln['userBal']} />
            <div className="vbtn_container container">
              <label title={!buttonManager.approve ? buttonManager.msg : null}><button disabled={!buttonManager.approve} className={buttonManager.approve ? 'button form_button' : 'disabled button'} onClick={approve}>Approve</button></label>
              <label title={!buttonManager.deposit ? buttonManager.msg : null}><button disabled={!buttonManager.deposit} className={buttonManager.deposit ? 'button form_button' : 'disabled button'} onClick={deposit}>Deposit</button></label>
            </div>
            <p>



              Next Token Unlock :  {vUData['nClaimTime']}<br />
              <b>Previously Claimed: {vUData['clAmount']} </b> ({vUData['lClaimTime']}) <br />


              <b>Next Claim Estimate:{vUData['nxclAmount']}</b></p><br/>
            {walletInfo && walletInfo.signer ? console.log("a", storedTokenInfo) : null}
            <label title={!buttonManager.withdraw ? buttonManager.msg : null}><button disabled={!buttonManager.withdraw} className={buttonManager.withdraw ? 'button form_button' : 'disabled button'} onClick={withdraw}>Withdraw</button></label>
          </div>

        </div>
        <img src={blueBlack} />
      </section>
    </>)
}

export { Vesting };
