import React, { useState, useEffect } from 'react';

const CustomInput = ({ input, setInput, inputErr, setInputErr, maxVal }) => {
  const handleInputChange = (e) => {
    e.preventDefault();
    const newValue = Math.min(parseFloat(e.target.value) || 0, maxVal);
    setInputErr();
    setInput(newValue);
  };

  const handleSliderChange = (e) => {
    const sliderValue = parseInt(e.target.value);
    const newValue = (maxVal * sliderValue) / 100;
    setInputErr();
    setInput(newValue);
  };

  // useEffect to set the initial value of the input to maxVal when the component mounts
  useEffect(() => {
    setInput(maxVal);
  }, [setInput, maxVal]);

  return (
    <div className='customInput-container'>
      <div className='error-screen'>
        <p className='error-txt'>{inputErr ? inputErr : null}</p>

      <input
        className="customInput"
        id="CustomInput"
        value={input}
        onChange={handleInputChange}
        max={maxVal}
        //<p className='input-max-btn' onClick={()=> setInput(maxVal)}>max</p>
      />
        <input
          className='error-slider'
          type="range"
          id="slider"
          name="slider"
          min="0"
          max="100"
          step="10"
          value={(input / maxVal) * 100}
          onChange={handleSliderChange}
        />      
        </div>

    </div>
  )
}

export { CustomInput };
