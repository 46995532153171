import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import TokenABI from "../config/abi/standardTokenABI"

async function TokenInfo( tokenAddress, provider ) {

  const infoDict = []

  try {
    //console.log('token info is running')
    let tokenContract = new ethers.Contract( tokenAddress, TokenABI.abi,  provider)
    const symbol = await tokenContract.symbol()
    const decimals = await tokenContract.decimals()
    const name =  await tokenContract.name()

    infoDict.push(
      //"symbol":symbol},{"decimals":decimals},{"name":name}
      [symbol,decimals,name,tokenAddress]
    );
  }
  catch (e) {
   // console.log('123error:',e)
  }
  //console.log('token info:',infoDict)
  return infoDict
}
export {TokenInfo};
