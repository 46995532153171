import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate  } from 'react-router-dom';
import { Slotmachine } from './slotmachine/Slotmachine';
import { Card } from '../../components';
import blueBlack from '../../assets/images/background/blue-black.jpg';
import { GameLobby } from '../../components/GameLobby';
import './games.scss';
import { useGlobalState } from '../../components/context/GlobalStateProvider';

const Games = () => {
    const [lobbyView, setLobbyView] = useState(false);
    const location = useLocation();
    const isPageLobby = location.pathname === '/dapp/games' || location.pathname === '/dapp/games/';
    const navigate = useNavigate();
    const { storedTokenInfo, setStoredTokenInfo, chainData, setChainData, walletInfo, setWalletInfo, isConnected } = useGlobalState();

    useEffect(()=>{
        if(isPageLobby){
            setLobbyView(true)
        } else {
            setLobbyView(false)
        }
    },[])

    const clickHandler = (i) => {
        setLobbyView(false)
        navigate('/dapp/games/'+i)
    }

    return (
        <div className='newsletter section'>
            <div className='gameDapp-container'>
                {lobbyView && <GameLobby clickHandler={clickHandler} />}
                <Routes>
                    <Route path="/slotMachine" element={<Slotmachine storedTokenInfo={storedTokenInfo} setStoredTokenInfo={setStoredTokenInfo} chainData={chainData} setChainData={setChainData} walletInfo={walletInfo} setWalletInfo={setWalletInfo} isConnected={isConnected} />} />
                </Routes>
            </div>
            <img id="imgsorc" src={blueBlack} />
        </div>
    );
};

export { Games };
