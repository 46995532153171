import React, {useState, useEffect} from 'react'
import blueBlack from '../../assets/images/background/blue-black.jpg'
import {Approve} from '../../modules/actions/approve';
import {MintWithEth} from '../../modules/nfts/mintWithEth';
import {NftInfo} from '../../modules/nfts/nftInfo';
import {NftContractInfo} from '../../modules/nfts/nftContractInfo';
import {NftETHFees} from '../../modules/nfts/nftEthFees';
import {NftERC20Fees} from '../../modules/nfts/nftERC20Fees';
import {Allowance} from '../../modules/allowance'
import {CustomInput, PlusMinusInput} from '../../components/inputs';
import { useGlobalState } from '../../components/context/GlobalStateProvider';
import tokensAddrs from '../../config/contracts/tokens.json'
import contractAddrs from '../../config/contracts/contracts.json'
import {NftGalleryPhotos} from '../../components/NftGalleryPhotos'
import { LootBoxController } from '../../components/lootbox/LootBoxController';
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router";
import './nft.css';
import { useError } from '../../components/context/ErrorProvider';
import { userNftCollection } from '../../modules/nfts/userNftCollection';
import { PLS_NFT_HOLDERS } from '../../config/configurations';

const NFTs = () =>{
    const { chainData, setChainData, walletInfo, isConnected } = useGlobalState();
    const [isApproved, setIsApproved] = useState(true)
    const [ethFee, setEthFee] = useState(0) //Returned as an array from function
    const [referFee, setReferFee] = useState(0)
    const [userReferFee, setUserReferFee] = useState(0)
    const [nftInfos, setNftInfos] = useState(0)
    const [input, setInput] = useState(1)
    const [referrer, setReferrer] = useState()
    const [displayWallet, setDisplayWallet] = useState(0)
    const [nftAddress, setNftAddress] = useState()
    const [allowanceData, setAllowanceData] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const [toPlay, setToPlay] = useState()
//set url para from referrer
    const urlRef = [searchParams.get('ref')]
    const { showPopUp, closePopup } = useError();
    const [usersNfts, setUsersNfts] = useState()

    useEffect(() => {
      const intervalId = setInterval(() => {
          getNftInfos();
      }, 6000);

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
  }, []);

  function timeout(delay: number) {
      return new Promise( res => setTimeout(res, delay) );
  }

    useEffect(() => { //WALLET INFO HERE!
      if (isConnected) {
        const fetchData = async () => {
          try {
            var chainId = "0x" + (369).toString(16);
            var nAddress = contractAddrs.NFTs[369]
            setNftAddress( nAddress)

            var tokenAddress = tokensAddrs.Friendship[369]

          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }

        setReferrer( urlRef[0] )
        console.log('ref value:',referrer)
        fetchData();


        ethFees()
        getNftInfos()
      }
    }, [isConnected])


  const handleChange = (event) => {
      setReferrer(event.target.value);
};

const wrongChain = () =>{
  showPopUp("NFTs currently only support PULSECHAIN", "error");
  setChainData({...chainData, chainId: "0x171" });
}
async function getNftInfos(){
  if(!isConnected){return}
  if(chainData.chainId !=="0x171"){
    wrongChain()
    return
  }
  console.log('nftAddress:', nftAddress, walletInfo.provider, walletInfo.signer )
  var nInfo = await NftContractInfo(  contractAddrs.NFTs[369], walletInfo.provider, walletInfo.signer )
  timeout(2000)
  let nftsDict = await userNftCollection(walletInfo?.signer?.address, PLS_NFT_HOLDERS)
  setUsersNfts(nftsDict)
  setNftInfos(nInfo)
  console.log('nftInfos',nftInfos)
}

async function ethFees(){
  timeout(2000)
  var fees = await NftETHFees( contractAddrs.NFTs[369], walletInfo.provider, walletInfo.signer)
  setEthFee(fees)
  var rfeeMain = Math.round(fees[4]*100)*fees[5]
  var userRefMain =  Math.round((fees[4]*100)*(1-fees[5]))
  setReferFee( rfeeMain )
  setUserReferFee(userRefMain)


}

async function DoEthMint(){
  if(!ethFee || !isApproved || !isConnected || !walletInfo?.signer?.address || !walletInfo?.signer?.provider ){console.log("DoEthMint: data missing"); return}

  //Amount will be a global variable set from the nftETHFees contract part
  //fees was returned (empty array from NftETHFees)
  var amount = Math.round(parseInt(input)*parseInt(ethFee[0])/(10**18)) //ethFees
   //referrer should come from the form where the referrer wallet is set

  console.log('MINT input',input, referrer)
  var result = await MintWithEth( contractAddrs.NFTs[369], input, referrer, amount, walletInfo.provider, walletInfo.signer ) //added result on async
  if(result[0]){
    showPopUp("Transaction Success: "+result[1], "success")
    setToPlay(1)
  } else {
    showPopUp("Error: "+ result[1], "error")
  }
}

//<button className='button' onClick={doApproval}>Approve</button>
//            Base Mint Fees:{parseInt(ethFee[1])/(10**18)}  PLS / ETH <br/>
//            Fee Increment:{parseInt(ethFee[2])}<br/>

    return(
        <section className="newsletter section">
            <div className='demoDapp-container'>
              <div className='centered-div customForm nftPage'>
            <h1 className="vhead">SommiWifHat NFT Page</h1>


            <LootBoxController input={input} setToPlay={setToPlay} toPlay={toPlay}/><br/>

            <div className="nftNum"><label>Quantity</label><PlusMinusInput name="quantity" value={input} increment={1} setValue={setInput} /></div><br/>
            <b>Mint Fee: { Math.round(input*parseInt(ethFee[0])/(10**18))} PLS </b><br/>
          <div>
            <button className='button' onClick={DoEthMint}>Mint</button>

          </div><br/><br/><br/>
<br/>
            Referal Wallet<input type="text" name="refWallet"  value={referrer} onChange={handleChange} /><br/>
            Referal Amount ({referFee}%): [{(parseInt(ethFee[0])/(10**18))*(referFee/100)}] PLS<br/>
            My Discount ({userReferFee}%): [{(parseInt(ethFee[0])/(10**18))*(userReferFee/100)}] PLS<br/>

            <p className="nftInfo">
            <b>Max NFT Supply: {parseInt(nftInfos[0])} |
            Cicurlating Supply: {parseInt(nftInfos[1])}  <br/>
            Left to Mint: {parseInt(nftInfos[2])} | CA:
             <a href="https://scan.mypinata.cloud/ipfs/bafybeih3olry3is4e4lzm7rus5l3h6zrphcal5a7ayfkhzm5oivjro2cp4/#/token/0x63989a43a1C00b9f61c9Bb0FE3fFd0CE5bA56427" target="_blank">
            {contractAddrs.NFTs[369]}</a> </b><br/>
            {usersNfts && <p><b><a href="https://sommifantoken.com/dapp/">View My NFTs  ({Object.keys(usersNfts).length})</a></b> |
            <a href="https://pulsemarket.app/collection/0x63989a43a1c00b9f61c9bb0fe3ffd0ce5ba56427" target="_blank"> Buy/Sell NFTs</a></p>}

There is currently {referFee+userReferFee}% that is for referral.
Sommi gets 50% of royalty fees and 45% of the mint fee.
Nft Lottery / Staking Rewards as well as dev fees. Please join our Telegram for announcements.
            </p>
              </div>
              <NftGalleryPhotos numberOfPhotos={25} showBtns={false} state={"Display"}/>
            <img src={blueBlack} />

        </div>

        </section>
    )
}

export {NFTs}
