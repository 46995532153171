import React, { useState, useEffect } from 'react';
import { Allowance } from '../../modules/allowance';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Vesting } from '../vesting';
import { NFTs } from '../nfts/NFTs';
import { Claims } from '../vesting';
import { Games } from '../games/Games';
import { Highscores } from '../highscores/HighScores';
import { ProfilePage } from '../Profile/ProfilePage';
import { useGlobalState } from '../../components/context/GlobalStateProvider';
import { Panel } from '../../components/Panel';
import greenBlack from '../../assets/images/background/green-black.jpg';
import { ConnectWithSelect } from '../../modules/wallet/ConnectWithSelect';
import './dapp.scss';

//import { DefaultProvider } from './components/DefaultProvider'

const DappBtn = ({ mobileTray, smallScreen }) => {
  return (
    <div className={!smallScreen ? "btn_container" : "btn_container_col"}>
      <a href="/dapp/"><button className={mobileTray ? "home_btn button mobile_bar_home_btn" : "home_btn button hidden_mobile_btn"}></button></a>
      <a href="/dapp/nfts"><button className={mobileTray ? 'button mobile_bar_btn' : "button hidden_mobile_btn"}>NFTs</button></a>
      {/* <a href="/dapp/scores"><button className={mobileTray ? 'button mobile_bar_btn' : "button hidden_mobile_btn"}>Highscores</button></a> */}
      {/* <a href="/dapp/games"><button className={mobileTray ? 'button mobile_bar_btn' : "button hidden_mobile_btn"}>Games</button></a>*/}
      <a href="/dapp/vesting"><button className={mobileTray ? 'button mobile_bar_btn' : "button hidden_mobile_btn"}>Vesting</button></a>
      <a href="/dapp/claims"><button className={mobileTray ? 'button mobile_bar_btn' : "button hidden_mobile_btn"}>Claims</button></a>
    </div>
  );
}

const Dapp = () => {
  const location = useLocation();
  const [mobileTray, setMobileTray] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);
  const { isConnected } = useGlobalState();

  const isDapp = location.pathname === '/dapp' || location.pathname === '/dapp/';

  useEffect(() => {
    // Function to check screen size and set the state
    const checkScreenSize = () => {
      if (window.innerWidth < 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    };

    // Initial check when component mounts
    checkScreenSize();

    // Add event listener to check screen size on resize
    window.addEventListener('resize', checkScreenSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []); // The dependency array is empty, so it runs only on mount and unmount

  if (!isConnected) return (
    <section className='media section bg-rpt' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0 }}>
      <div style={{marginBottom: '10em'}} className={mobileTray && smallScreen ? 'button-bar'
        : !mobileTray && !smallScreen ? 'button-bar' :
          'hidden'}>
        <DappBtn mobileTray={mobileTray} smallScreen={smallScreen} />
      </div>
      <div className='panel' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', paddingBottom: '25px'}}>
        <h4>PLEASE CONNECT WALLET<br /> TO ACCESS DAPP</h4>
        <ConnectWithSelect />
      </div>
      <img src={greenBlack} />
    </section>
  )

  return (
    <section className="media section bg-rpt" style={{ padding: 0 }}>
      {smallScreen && <button className="floating_btn button" onClick={() => setMobileTray(p => !p)}></button>}
      <div className={mobileTray && smallScreen ? 'button-bar'
        : !mobileTray && !smallScreen ? 'button-bar' :
          'hidden'}>
        <DappBtn mobileTray={mobileTray} smallScreen={smallScreen} />
      </div>
      
        {isDapp && <ProfilePage />}

        <Routes>
          <Route path="/nfts" element={<NFTs />} />
          <Route path="/vesting" element={<Vesting />} />
          <Route path="/claims" element={<Claims />} />
          {/*<Route path='/scores' element={<Highscores />} />*/}
          <Route path="/games/*" element={<Games />} />
        </Routes>
    </section>
  );
}

export { Dapp };
