import React, { useState, useEffect } from 'react';
import { ethers } from "ethers";
import { getErrorMessage } from '../errorHandler';

import NftABI from "../../config/abi/NftABI.json";

async function MintWithEth( nftAddress, quantity, referrer, amount, provider, signer ){

  function checkAddress( wallet ) {
    var refWallet = wallet
    if (wallet === null || wallet === undefined ) {
      refWallet = '0x0000000000000000000000000000000000000000'
      //
    }
    return refWallet
  }

  var mint = [true, '']
  var refAddress = checkAddress( referrer )
  //console.log('my nft eth:', quantity, nftAddress, refAddress,amount)
  try {
    let nftContract = new ethers.Contract( nftAddress, NftABI.abi, provider).connect(signer)
    //const options = { value: amount };

    var count = ethers.parseUnits( amount.toString(), 18);
    const options = {  value: count  };
    let tx = await nftContract.mintWithEther(quantity, refAddress, options)
    const receipt = await tx.wait();
    mint = [true, receipt.blockHash ]

// Often you may wish to wait until the transaction is mined

  }
  catch (e) {
    //console.log('my deposit error:',e)
    //console.log('my mint error:', e.message )
    var errorMsg = getErrorMessage(e)
    mint = [false, errorMsg]
  }
  return mint
}

export {MintWithEth};
