import React from 'react';
import gamelogo from '../assets/images/sommi-fan-token-logo-circle.png';
import { Card } from './Card';

const GameDict = {
    SlotMachine: {
    logo: gamelogo,
    description: "Spin the reels: Winning occurs when specific symbol combinations align across paylines.",
    enabled: false },
    CoinFlip: {
      logo: gamelogo,
      description: "Spin the reels: Winning occurs when specific symbol combinations align across paylines.",
      enabled: false
    }
};

const GameLobby = ({clickHandler}) =>{
    const gameList = Object.keys(GameDict);

    return(
        <div id="game-lobby" className="container-sm" >
          {gameList.map((g, index) => (
            <li /*onClick={()=>clickHandler(g)}*/><Card key={index} header={g} imgSrc={GameDict[g].logo} body={ GameDict[g].enabled ? GameDict[g].description : "Coming Soon.."} /></li>
          ))}
        </div>
      )
}

export {GameLobby}